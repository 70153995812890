import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentDivider.figmaUrl.ios} codeUrl={checklists.componentDivider.codeUrl.ios} checklists={checklists.componentDivider.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`LGNDivider`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` is a custom view that can be used to visually separate vertical content in a user interface.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` with the following appearances:`}</p>
    <ul>
      <li parentName="ul">{`With text or without text`}</li>
      <li parentName="ul">{`Text size`}</li>
      <li parentName="ul">{`Defined thickness of divider`}</li>
      <li parentName="ul">{`Color of divider`}</li>
      <li parentName="ul">{`Leading and trailing padding`}</li>
    </ul>
    <h2>{`Usage`}</h2>
    <p>{`Example of `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` using text and padding`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/LGNDivider_text_padding.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNDivider(
    text: "Sign up",
    font: LGNFont.bodySmallRegular,
    color: LGNColor.tertiary300,
    padding: 10)
`}</code></pre>
    <hr></hr>
    <p>{`Example of `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` without text and padding`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/LGNDivider_no_text_no_padding.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNDivider(
    font: LGNFont.bodySmallRegular,
    color: LGNColor.primary500)
`}</code></pre>
    <hr></hr>
    <p>{`Example of `}<inlineCode parentName="p">{`LGNDivider`}</inlineCode>{` with defined thickness level`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/LGNDivider_defined_thickness.png",
      "alt": "usage iOS divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNDivider(
    text: "Sign up",
    font: LGNFont.bodySmallRegular,
    thickness: 7,
    color: LGNColor.secondary500)
`}</code></pre>
    <div className="divi" />
    <h2><strong parentName="h2">{`Attributes`}</strong></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text content for divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Font`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Font attribute for text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thickness`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manage the thickness of divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color to be used for the text and divider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LGNColor.tertiary800`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Divider’s leading and trailing padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      